import React from "react";
import Layout from "@/components/layout";
import ContentSucesso from "@/components/OutrosForm";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";

const Outros = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Solicitação para outros serviços">
          <ContentSucesso />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Outros;
